import { mapGetters } from "vuex"
import {
  HIGHLIGHTED_ISSUES_WIDGET,
  WIDGET_CLASS,
  QUERY_MAP,
  MESSAGE_VIEW_WIDGETS,
  ISSUE_VIEW_WIDGETS
} from "@/constants"

export default {
  name: "Dashboard",
  data() {
    return {
      issueSkeletonLoaderNumber: 4
    }
  },
  computed: {
    ...mapGetters({
      loggedInUser              : "auth/loggedInUser",
      loggedInUserPolicies      : "accessControl/loggedInUserPolicies",
      issuesWithUnreadMessages  : "kpis/issuesWithUnreadMessages",
      issuesWithUnansweredReport: "kpis/issuesWithUnansweredReport",
      issueWithDueDate          : "kpis/issueWithDueDate",
      issuesWhichAreOverdue     : "kpis/issuesWhichAreOverdue",
      isLoadingKpis             : "kpis/isLoadingKpis",
      loggedInUserRoleTypes     : "auth/loggedInUserRoleTypes"
    }),
    hasIssueAccessForDueDateProperty() {
      return this.loggedInUserPolicies["Issue view"]?.some(policy => policy.select.includes("dueDate"))
    },
    loggedInUserName(){
      return this.loggedInUser.name
    },
    issuesWithUnreadMessagesCount() {
      return this.issuesWithUnreadMessages.length
    },
    issuesWithUnansweredReportCount() {
      return this.issuesWithUnansweredReport.length
    },
    issueWithDueDateCount() {
      return this.issueWithDueDate.length
    },
    issuesWhichAreOverdueCount() {
      return this.issuesWhichAreOverdue.length
    },
    kpiValues() {
      return {
        ISSUE_DUE_SOON           : this.issueWithDueDateCount,
        ISSUE_UNREAD_MESSAGES    : this.issuesWithUnreadMessagesCount,
        ISSUE_UNANSWERED_MESSAGES: this.issuesWithUnansweredReportCount,
        ISSUE_OVERDUE            : this.issuesWhichAreOverdueCount
      }
    },
    kpisToDisplay() {
      return Object.keys(this.widgetsToDisplay)
        .map(key => ({
          key,
          label   : this.$t(this.widgetsToDisplay[key].label),
          value   : this.kpiValues[key],
          disabled: !this.kpiValues[key]
        }))
    },
    hasAccessToMessageView() {
      return !!this.loggedInUserPolicies["Message view"]
    },
    widgetsToDisplay() {
      const widgetsToDisplay = {}
      for (const key in HIGHLIGHTED_ISSUES_WIDGET) {
        if (this.hasAccessToMessageView) {
          if (MESSAGE_VIEW_WIDGETS.includes(key)) {
            widgetsToDisplay[key] = HIGHLIGHTED_ISSUES_WIDGET[key]
          }
        }
        if (this.hasIssueAccessForDueDateProperty) {
          if (ISSUE_VIEW_WIDGETS.includes(key)) {
            widgetsToDisplay[key] = HIGHLIGHTED_ISSUES_WIDGET[key]
          }
        }
      }
      return widgetsToDisplay
    }
  },
  methods: {
    getItemClass(hover, spanClass) {
      return spanClass ? { [WIDGET_CLASS.HOVER_LABEL]: hover } : { [WIDGET_CLASS.HOVER_ICON]: hover }
    },
    getColorClass(item) {
      return item.disabled ? WIDGET_CLASS.INDICATOR_DISABLED : WIDGET_CLASS.INDICATOR_ACTIVE
    },
    handleKpiItemClick(item) {
      const queryToPush = QUERY_MAP[item.key] || {}
      this.$router.push({ name: "issues", query: queryToPush })
    }
  }
}